






































import Vue from 'vue'
import Review from './Review.vue'
import formatters from '@/utils/formatters'

export default Vue.extend({
  name: 'AdSetsTable',
  components: {
    Review
  },
  props: {
    AdSets: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      clicked_ids: []
    }
  },
  methods: {
    getStringNumber (num) {
      const value = formatters.getStringNumber(num)
      return num > 0 ? value + ' people' : 'N/A'
    },
    clickOnId (id) {
      if (this.clicked_ids.includes(id)) this.clicked_ids = this.clicked_ids.filter(i => i !== id)
      else this.clicked_ids.push(id)
    }
  },
  mounted () {
    this.clicked_ids.push(this.AdSets[0].id)
  }
})
