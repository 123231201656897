
















































































































































import Vue from 'vue'
import Ad from '@/components/ADSManager/NewCampaign/Publish/Ad.vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'Review',
  props: {
    ad_set: {
      required: true,
      type: Object
    }
  },
  components: { Ad },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('adsManager/adDesign', ['getAdDesignData', 'getCallToActions']),
    ...mapGetters('adsManager', ['getCampaign', 'getLocales']),
    ...mapGetters('budget', ['getCampaigns_budget_data']),
    age () {
      return this.ad_set.formatted_targeting.age_min + ' - ' + this.ad_set.formatted_targeting.age_max
    },
    genders () {
      switch (this.ad_set.formatted_targeting.genders) {
        case 0:
          return 'Male and female'
        case 1:
          return 'Male'
        case 2:
          return 'Female'
        default:
          return 'Male and Female'
      }
    },
    ad_format () {
      switch (this.getAdDesignData?.creative_format) {
        case 'normal':
          return 'Image'
        case 'carousel':
          return 'Carousel'
        default:
          return ''
      }
    },
    interests () {
      return this.ad_set?.formatted_targeting?.interests ? this.ad_set.formatted_targeting.interests.map(i => i.name).join(';') : null
    },
    inclusion_interests () {
      const arr = []
      if (this.ad_set.formatted_targeting.inclusion_targeting) {
        this.ad_set.formatted_targeting.inclusion_targeting.forEach((int) => {
          Object.keys(int).forEach(i => {
            const obj = {}
            obj['type'] = i
            int[i].forEach(t => {
              const final = { ...obj, ...t }
              arr.push(final)
            })
          })
        })
      }
      return arr
    },
    exclusion_interests () {
      const arr = []
      if (this.ad_set.formatted_targeting.exclusion_targeting) {
        Object.keys(this.ad_set.formatted_targeting.exclusion_targeting).forEach(int => {
          const obj = {}
          obj['type'] = int
          this.ad_set.formatted_targeting.exclusion_targeting[int].forEach(t => {
            const final = { ...obj, ...t }
            arr.push(final)
          })
        })
      }
      return arr
    },
    included_locations () {
      if (!this.ad_set.formatted_targeting.locations) {
        return []
      } else {
        return this.ad_set.formatted_targeting.locations
          .filter(l => l.is_included)
          .map(l => {
            if (l.region) {
              return {
                ...l,
                code: l.key,
                label: `${l.name}, ${l.region}, ${l.country_name}`,
                radius_title: l.radius ? `(+${l.radius} ${l.distance_unit})` : ''
              }
            } else {
              return {
                ...l,
                code: l.key,
                label: `${l.name}, ${l.country_name}`,
                radius_title: l.radius ? `(+${l.radius} ${l.distance_unit})` : ''
              }
            }
          })
      }
    },
    excluded_locations () {
      if (!this.ad_set.formatted_targeting.locations) {
        return []
      } else {
        return this.ad_set.formatted_targeting.locations
          .filter(l => !l.is_included)
          .map(l => {
            if (l.region) {
              return {
                ...l,
                code: l.key,
                label: `${l.name}, ${l.region}, ${l.country_name}`,
                chosen_radius: l.type === 'city' ? 25 : null,
                radius_title: l.radius ? `(+${l.radius} ${l.distance_unit})` : ''
              }
            } else {
              return {
                ...l,
                code: l.key,
                label: `${l.name}, ${l.country_name}`,
                chosen_radius: l.type === 'city' ? 25 : null,
                radius_title: l.radius ? `(+${l.radius} ${l.distance_unit})` : ''
              }
            }
          })
      }
    },
    locales () {
      return this.ad_set?.formatted_targeting?.locales ? this.ad_set.formatted_targeting.locales.map(locale => this.getLocales.find(l => l.key === locale)) : []
    }
  }
})
