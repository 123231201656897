











































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Ad',
  props: {
    ad: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('adsManager/adDesign', ['getCallToActions', 'getCreativeFormat']),
    image_url () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.media?.attachment?.link ? process.env.VUE_APP_BASE_URL + this.ad.creative.media.attachment.link : null
      } else if (this.getCreativeFormat === 'carousel') {
        return process.env.VUE_APP_BASE_URL + this.ad.child_attachment.media.attachment.link
      }
      return null
    },
    headline () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.headline
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment.headline.headline
      }
      return null
    },
    url_url () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.url?.url
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment?.url?.url
      }
      return null
    },
    url_link_view () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.url?.url
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment?.url?.url
      }
      return null
    },
    url_display_link () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.url?.link_view
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment?.url?.url
      }
      return null
    },
    ad_text () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.ad_text
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment.ad_text.ad_text
      }
      return null
    },
    ad_description () {
      if (this.getCreativeFormat === 'normal') {
        return this.ad?.creative?.description
      } else if (this.getCreativeFormat === 'carousel') {
        return this.ad.child_attachment?.ad_text?.ad_text
      }
      return null
    },
    call_to_action () {
      const path = (this.getCreativeFormat === 'normal' && this.ad.creative?.call_to_action ? 'creative'
        : (this.getCreativeFormat === 'carousel' && this.ad.child_attachment.call_to_action ? 'child_attachment' : null))
      if (!path) {
        return null
      }
      const findCallToAction = (callToAction) => {
        return callToAction.key === this.ad[path].call_to_action.type
      }
      const getCallToAction = this.getCallToActions
      if (!Array.isArray(getCallToAction)) {
        return getCallToAction[
          Object.keys(getCallToAction).find((key: string): boolean => {
            return getCallToAction[key].find(findCallToAction)
          })].find(findCallToAction)?.value
      }
      return getCallToAction.find(findCallToAction)?.value
    }
  }
})
