






















































































































import Vue from 'vue'
import NewCampaignLayout from '@/components/ADSManager/NewCampaignLayout.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import Card from '@/components/Card.vue'
import AdSetsTable from './Publish/AdSetsTable.vue'
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
  name: 'Publish',
  components: {
    NewCampaignLayout,
    BasePagePreloader,
    Card,
    AdSetsTable
  },
  props: {
    campaign_id: {
      required: true
    }
  },
  data () {
    return {
      optimize_for: {
        IMPRESSIONS: 'Impressions',
        LINK_CLICKS: 'Link Clicks',
        POST_ENGAGEMENT: 'Post Engagement',
        LEAD_GENERATION: 'Lead Generation',
        AD_RECALL_LIFT: 'Ad Recall Lift',
        REACH: 'Reach',
        LANDING_PAGE_VIEWS: 'Landing Page Views',
        OFFSITE_CONVERSIONS: 'Conversions'
      },
      loading: true
    }
  },
  created () {
    Promise.all([
      this.loadAccounts({
        search: { service: 'facebook', target: 'ads_management' }
      }),
      this.load_campaign({
        campaignId: this.campaign_id,
        params: {
          'include[0]': 'ad_sets',
          'include[1]': 'ad_sets.ads',
          'include[2]': 'budget',
          'include[3]': 'ad_sets.ads.creative',
          'include[4]': 'ad_sets.ads.creative.call_to_action',
          'include[5]': 'ad_sets.ads.creative.media',
          'include[6]': 'ad_sets.ads.creative.media.attachment',
          'include[7]': 'ad_sets.ads.creative.url',
          'include[8]': 'ad_sets.ads.creative.carousel.child_attachment.media.attachment',
          'include[9]': 'ad_sets.ads.creative.carousel.child_attachment.headline',
          'include[10]': 'ad_sets.ads.creative.carousel.child_attachment.url',
          'include[11]': 'ad_sets.ads.creative.carousel.child_attachment.call_to_action',
          'include[12]': 'ad_sets.ads.creative.carousel.child_attachment.ad_text'
        }
      }),
      this.load_campaign_objective(),
      this.load_campaign_targeting(this.campaign_id),
      this.get_ad_set_info(this.campaign_id)
    ]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('adsManager', ['load_campaign', 'load_campaign_objective', 'load_ad_sets', 'publish_campaign', 'load_campaign_targeting']),
    ...mapActions('notifications', ['addExceptions']),
    ...mapActions('adsManager/adDesign', ['get_ad_set_info', 'reset_ad_design_data']),
    publish_campaign_request () {
      this.publish_campaign(this.campaign_id)
        .then(() => {
          this.reset_ad_design_data()
          this.$router.push({ name: 'CampaignPublish' })
        })
        .catch(er => {
          if (er.response && er.response.data.errors) {
            this.addExceptions(er.response.data)
          }
        })
    },
    // formattedTime (time) {
    //   return this.$moment(time, ['YYYY-MM-DD HH:mm:ss a']).format('YYYY-MM-DD hh:mm:ss A')
    // },
    prev_click () {
      this.$router.push({ name: 'NewCampaignBudget', props: { campaign_id: this.campaign_id } })
    }
  },
  computed: {
    ...mapGetters('adAccounts', ['getById']),
    ...mapGetters('adsManager', ['getCampaign', 'getObjectiveName', 'getAdSets', 'getCampaignBudget', 'getCampaignCurrencySymbol']),
    ...mapGetters('budget', ['getCampaigns_budget_data']),
    account_name () {
      return this.getById(this.getCampaign?.account_id)?.name
    },
    objective () {
      return this.getObjectiveName(this.getCampaign?.objective)
    },
    campaign_name () {
      return this.getCampaign?.name
    },
    budget_info () {
      const period = +this.getCampaigns_budget_data.data?.budget_type_time === 1 ? 'Total' : 'Daily'
      return `${period} - ${this.getCampaigns_budget_data.data?.amount} ${this.getById(this.getCampaign.account_id).currency}`
    },
    budget_chosen () {
      return this.getCampaignBudget?.amount
    },
    bid_info () {
      if (this.getCampaigns_budget_data.data?.bidding) {
        let s = ''
        let with_bid = true
        switch (this.getCampaigns_budget_data.data.bidding) {
          case 'LOWEST_COST_WITH_BID_CAP':
            s = 'Lowest cost with bid cap'
            break
          case 'LOWEST_COST':
            s = 'Lowest cost'
            break
          case 'LOWEST_COST_WITHOUT_CAP':
            s = 'Lowest cost without cap'
            with_bid = false
            break
          default:
            break
        }
        if (with_bid) {
          return s + ' ' + this.getCampaigns_budget_data.data?.bid + ' ' + this.getById(this.getCampaign.account_id).currency
        } else return s
      } else {
        return ''
      }
    },
    optimize_for_name () {
      return this.optimize_for[this.getCampaigns_budget_data.data?.optimize_for]
    },
    pay_for_name () {
      return this.optimize_for[this.getCampaigns_budget_data.data?.pay_for]
    },
    // schedule () {
    //   let s = null
    //   if (this.getCampaignBudget?.start_time) {
    //     s = 'Start: ' + this.getCampaignBudget.start_time
    //   }
    //   if (this.getCampaignBudget?.end_time) {
    //     s = s + ' Stop: ' + this.getCampaignBudget.end_time
    //   }
    //   return s || '-'
    // },
    scheduleStart () {
      if (this.getCampaignBudget?.start_time) {
        // return ('Start: ' + this.$moment(this.getCampaignBudget.start_time).format('YYYY-MM-DD HH:mm'))
        return ('Start date: ' + this.$moment(this.getCampaignBudget.start_time, ['YYYY-MM-DD HH:mm:ss a']).format('YYYY-MM-DD hh:mm:ss A'))
      }
      return null
    },
    scheduleEnd () {
      if (this.getCampaignBudget?.end_time) {
        // return ('End date: ' + this.$moment(this.getCampaignBudget.end_time).format('YYYY-MM-DD HH:mm'))
        return ('End date: ' + this.$moment(this.getCampaignBudget.end_time, ['YYYY-MM-DD HH:mm:ss a']).format('YYYY-MM-DD hh:mm:ss A'))
      }
      return null
    }
  }
})
